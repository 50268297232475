import React from "react"
import styled from "@emotion/styled"

import LoadingIndicator from "./loading-indicator"
import Image from "./image"
import { colors } from "../styles/colors"

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

class ImageLoader extends React.Component {
  state = {
    isLoading: true,
  }

  handleLoaded = (e) => {
    this.setState({ isLoading: false })
  }

  render() {
    return (
      <OuterContainer>
        <InnerContainer>
          <LoadingIndicator
            isLoading={this.state.isLoading}
            color={colors.primaryLight}
          />
        </InnerContainer>
        <Image {...this.props} onLoad={this.handleLoaded} />
      </OuterContainer>
    )
  }
}

export default ImageLoader
