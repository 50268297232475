import React from "react"
import styled from "@emotion/styled"

import Seo from "../components/seo"
import Layout from "../components/layout"

import ImageLoader from "../components/image-loader"
import { borders, shadows } from "../styles"

const StudiosContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-top: 2rem;

  @media screen and (min-width: 768px) {
    gap: 4rem;
  }

  @media screen and (min-width: 1280px) {
    gap: 6rem;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    align-items: center;

    &:nth-of-type(odd) {
      flex-direction: row-reverse;
    }

    &:last-of-type {
      margin-bottom: 5rem;
    }
  }
`

const Blurb = styled.div`
  padding: 0 1rem;

  @media screen and (min-width: 1280px) {
    width: 40%;
  }
`

const Image = styled.div`
  flex: 1;
  border: ${borders.standard};
  border-radius: 0;
  box-shadow: ${shadows.standard};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

export default class TeachersPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="Teachers | Sing Smart Studios | Townsville Singing School" />
        <StudiosContainer>
          <ContentContainer>
            <Blurb>
              <h1>Our studios</h1>
              <p>
                Sing Smart Studios is a local family run business with four
                studio rooms on premise.
              </p>
            </Blurb>
            <Image>
              <ImageLoader
                src="/assets/studio-1b.jpg"
                alt="Studios Exterior"
                objectFit="cover"
              />
            </Image>
          </ContentContainer>

          <ContentContainer>
            <Blurb>
              <h1>Private and comfy</h1>
              <p>
                The spaces are freshly renovated, sound-proof and have been
                fitted out with high quality furnishings so you can practise in
                comfort and style.
              </p>
            </Blurb>
            <Image>
              <ImageLoader
                src="/assets/adult-student.jpg"
                alt="Studios Exterior"
                objectFit="cover"
              />
            </Image>
          </ContentContainer>

          <ContentContainer>
            <Blurb>
              <h1>Good gear</h1>
              <p>
                You'll find and use high quality audio and recording equipment
                in each room. Some of the many brands we use on site are RODE,
                Shure, Roland, Yamaha!
              </p>
            </Blurb>
            <Image>
              <ImageLoader
                src="/assets/microphone-creative.jpg"
                alt="Studios Exterior"
                objectFit="cover"
              />
            </Image>
          </ContentContainer>
        </StudiosContainer>
      </Layout>
    )
  }
}
